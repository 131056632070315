import React, { useEffect, useState } from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { useUserContext } from 'contexts/UserContext/UserContextProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ErrorBoundary as FallbackErrorBoundary } from 'react-error-boundary';

import { LocalError } from 'components/errorBoundary/LocalError';

if (window.bugsnagAPIKey && !document.cookie?.includes('use_local_dev')) {
  Bugsnag.start({
    releaseStage: window.firehydrant_environment,
    apiKey: window.bugsnagAPIKey,
    plugins: [new BugsnagPluginReact()],
  });
}

const ErrorBoundaryComponent = Bugsnag.isStarted()
  ? Bugsnag.getPlugin('react').createErrorBoundary(React)
  : FallbackErrorBoundary;

export default function ErrorBoundary({ ...props }) {
  const { currentUser, account, currentOrganization } = useUserContext();
  const [versions, setVersions] = useState({});
  const flags = useFlags();

  const versionCheck = async () => {
    try {
      const body = await fetch('/infra/health').then((response) =>
        response.json(),
      );
      setVersions({
        revision: body.revision,
      });
    } catch (err) {
      if (Bugsnag.isStarted()) {
        Bugsnag.notify('Error fetching /infra/health in ErrorBoundary');
      }
    }
  };

  useEffect(() => {
    versionCheck();
  }, []);

  const gitRevision = versions?.revision;

  if (Bugsnag.isStarted()) {
    Bugsnag.setUser(currentUser?.id, currentUser?.email, currentUser?.name);
    Bugsnag.addMetadata('tags', currentUser?.tags);
    Bugsnag.addMetadata('app', {
      appVersion: gitRevision,
    });
    Bugsnag.addMetadata('account', {
      id: account?.id,
      name: account?.name,
    });
    Bugsnag.addMetadata('organization', {
      id: currentOrganization?.node?.id,
      name: currentOrganization?.node?.name,
    });
    Bugsnag.addMetadata('flags', flags || {});
  }

  return <ErrorBoundaryComponent {...props} FallbackComponent={LocalError} />;
}
