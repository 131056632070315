import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Flex } from '@chakra-ui/react';
import { Center, List, ListItem } from '@firehydrant/design-system';
import { toastEmitter } from 'helpers/eventEmitter';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';

import Toast from './Toast';
import ToastContext from './ToastContext';

export const useToastContext = () => {
  return useContext(ToastContext);
};

export const ToastContextProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const [duration, setDuration] = useState(2800);

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(
        () => setToasts((toasts) => toasts.slice(1)),
        duration,
      );
      return () => clearTimeout(timer);
    }
  }, [toasts]);

  const addToast = useCallback(
    function (toast, delay = 2800) {
      setToasts((toasts) => [toast, ...toasts]);
      setDuration(delay);
    },
    [setToasts],
  );

  const removeToasts = useCallback(
    function () {
      setToasts([]);
    },
    [setToasts],
  );

  const removeItem = (index) => {
    setToasts(toasts.filter((t, i) => index !== i));
  };

  useEffect(() => {
    const showHandler = (message) => {
      addToast(message);
    };

    toastEmitter.on('showGlobalToast', showHandler);

    // Clean up when the component unmounts
    return () => {
      toastEmitter.off('showGlobalToast', showHandler);
    };
  }, [addToast]);

  return (
    <ToastContext.Provider value={{ addToast, removeToasts, toasts }}>
      <Flex justifyContent="center">
        <List position="fixed" top="15px" zIndex={1000000}>
          {toasts?.map((toast, index) => (
            <ListItem key={uuidV4()}>
              <Center>
                <Toast
                  removeItem={removeItem}
                  toast={toast}
                  index={index}
                  duration={duration}
                />
              </Center>
            </ListItem>
          ))}
        </List>
      </Flex>
      {children}
    </ToastContext.Provider>
  );
};

ToastContextProvider.propTypes = {
  children: PropTypes.node,
};
